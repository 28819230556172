import vue from "vue";
import axios from "axios";
import { get } from "lodash";

import TokenService from "./services/TokenService";
import { getConfig } from "./config";

const cfg = getConfig();
// const instance = axios.create({ withCredentials: true })
const instance = axios.create();
instance.defaults.baseURL = cfg.apiUrl;
// instance.defaults.headers["Pragma"] = "no-cache";
instance.defaults.withCredentials = true;

instance.interceptors.request.use((config) => {
	const token = TokenService.getToken();
	const anonymous = get(config.data, "anonymous", false);
	if (token && anonymous === false)
		config.headers.Authorization = `Bearer ${token}`;
	return config;
});

instance.interceptors.response.use(
	(response) => {
		const token = get(response, "data.auth.token");
		if (token) TokenService.setToken(token);

		if (
			response.headers["content-type"] ===
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		)
			return response.data;
		return response.data.data;
	},
	(error) => {
		const reason = get(error, "response.data.httpDescription");
		const user = get(error, "response.data.info.userLogin");
		const type = get(error, "response.data.error.type");
		const status = get(error, "response.status");

		vue.appInsights.trackTrace({
			message: reason,
			severity: 2,
			properties: {
				user: user,
				status: status,
			},
		});

		if (status === 401 && location.pathname !== "/login") {
			TokenService.removeToken();
			location.href = `/?returnUrl=${encodeURIComponent(location.pathname)}`;
		} else {
			return Promise.reject({
				reason,
				user,
				status,
				type,
				inner: error,
			});
		}
	},
);

export default instance;
