export const getConfig = () => {
	const configs = [
		// test
		{
			hosts: [
				"localhost",
				"portal-test.smartretur.no",
				"digipall-test.smartretur.no",
				"digipall2-test.smartretur.no",
			],

			apiUrl: "https://api-dev.smartretur.no/api/1/nor/",
			// apiUrl: "https://api-dev.smartretur.no/api/1/sve/",
			// apiUrl: "https://api-dev.smartretur.no/api/1/dan/",
			// apiUrl: "https://api365-dev.smartretur.no/api/1/nor/",
			// apiUrl: "https://api-qa.smartretur.no/api/1/nor/",
			// apiUrl: "https://api-qa.smartretur.no/api/1/sve/",
			// apiUrl: "https://api-qa.smartretur.no/api/1/dan/",
			// apiUrl: "https://api-dev.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		{
			hosts: [
				"portal-test.smartretur.se",
				"digipall-test.smartretur.se",
				"digipall2-test.smartretur.se",
			],
			apiUrl: "https://api-dev.smartretur.no/api/1/sve/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "default",
		},
		{
			hosts: [
				"portal-test.smartretur.dk",
				"digipall-test.smartretur.dk",
				"digipall2-test.smartretur.dk",
			],
			apiUrl: "https://api-dev.smartretur.no/api/1/dan/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "default",
		},
		{
			hosts: [
				"portal-test.provipal.dk",
				"ppaportal-test.smartretur.no",
				"ppaportal-test.smartretur.dk",
			],
			apiUrl: "https://api-dev.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "ppa",
		},
		{
			hosts: ["portal-test.smartwash.dk"],
			apiUrl: "https://api-dev.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "swa",
		},
		{
			hosts: ["portal-test.smartwash.dk"],
			apiUrl: "https://api-dev.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "swa",
		},

		// qa
		{
			hosts: ["portal-qa.smartretur.no"],
			apiUrl: "https://api-qa.smartretur.no/api/1/nor/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		{
			hosts: ["portal-qa.smartretur.se"],
			apiUrl: "https://api-qa.smartretur.no/api/1/sve/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "default",
		},
		{
			hosts: ["portal-qa.smartretur.dk"],
			apiUrl: "https://api-qa.smartretur.no/api/1/dan/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "default",
		},
		{
			hosts: ["portal-qa.smartwash.dk"],
			apiUrl: "https://api-qa.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "swa",
		},
		{
			hosts: [
				"portal-qa.provipal.dk",
				"ppaportal-qa.smartretur.no",
				"ppaportal-qa.smartretur.dk",
			],
			apiUrl: "https://api-qa.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "ppa",
		},

		// 365 qa
		{
			hosts: ["portal365-qa.smartretur.no"],
			apiUrl: "https://api365-qa.smartretur.no/api/1/nor/",
			assetsUrl: "https://api365-qa.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// 365 test
		{
			hosts: [
				"portal365-test.smartretur.no",
				"digipall365-test.smartretur.no",
				"digipall2365-test.smartretur.no",
			],
			apiUrl: "https://api365-dev.smartretur.no/api/1/nor/",
			assetsUrl: "https://api365-dev.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		{
			hosts: [
				"portal365-test.smartretur.se",
				"digipall365-test.smartretur.se",
				"digipall2365-test.smartretur.se",
			],
			apiUrl: "https://api365-dev.smartretur.no/api/1/sve/",
			assetsUrl: "https://api365-dev.smartretur.no",
			locales: "main",
			theme: "default",
		},
		{
			hosts: [
				"portal365-test.smartretur.dk",
				"digipall365-test.smartretur.dk",
				"digipall2365-test.smartretur.dk",
			],
			apiUrl: "https://api365-dev.smartretur.no/api/1/dan/",
			assetsUrl: "https://api365-dev.smartretur.no",
			locales: "main",
			theme: "default",
		},
		{
			hosts: ["portal365-test.smartwash.dk"],
			apiUrl: "https://api365-dev.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api365-dev.smartretur.no",
			locales: "main",
			theme: "swa",
		},
		{
			hosts: ["portal365-test.provipal.dk"],
			apiUrl: "https://api365-dev.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api365-dev.smartretur.no",
			locales: "main",
			theme: "ppa",
		},
		{
			hosts: ["digipall365-test.provipal.dk"],
			apiUrl: "https://api365-dev.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api365-dev.smartretur.no",
			locales: "main",
			theme: "ppa",
		},
		{
			hosts: ["digipall2365-test.provipal.dk"],
			apiUrl: "https://api365-dev.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api365-dev.smartretur.no",
			locales: "main",
			theme: "ppa",
		},

		// prod
		{
			hosts: [
				"portal.smartretur.no",
				"digipall.smartretur.no",
				"digipall2.smartretur.no",
			],
			apiUrl: "https://api.smartretur.no/api/1/nor/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// 365 prod NOR
		{
			hosts: [
				"portal365.smartretur.no",
				"digipall365.smartretur.no",
				"digipall2365.smartretur.no",
			],
			apiUrl: "https://api365.smartretur.no/api/1/nor/",
			assetsUrl: "https://api365.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},

		{
			hosts: [
				"portal.smartretur.se",
				"digipall.smartretur.se",
				"digipall2.smartretur.se",
			],
			apiUrl: "https://api.smartretur.no/api/1/sve/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "default",
		},
		// 365 prod SVE
		{
			hosts: [
				"portal365.smartretur.se",
				"digipall365.smartretur.se",
				"digipall2365.smartretur.se",
			],
			apiUrl: "https://api365.smartretur.no/api/1/sve/",
			assetsUrl: "https://api365.smartretur.no",
			locales: "main",
			theme: "default",
		},

		{
			hosts: [
				"portal.smartretur.dk",
				"digipall.smartretur.dk",
				"digipall2.smartretur.dk",
			],
			apiUrl: "https://api.smartretur.no/api/1/dan/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// 365 prod DAN
		{
			hosts: [
				"portal365.smartretur.dk",
				"digipall365.smartretur.dk",
				"digipall2365.smartretur.dk",
			],
			apiUrl: "https://api365.smartretur.no/api/1/dan/",
			assetsUrl: "https://api365.smartretur.no",
			locales: "main",
			theme: "default",
		},
		{
			hosts: ["portal.provipal.dk", "ppaportal.smartretur.dk"],
			apiUrl: "https://api.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "ppa",
		},
		{
			hosts: ["portal365.provipal.dk", "ppaportal365.smartretur.dk"],
			apiUrl: "https://api365.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api365.smartretur.no",
			locales: "main",
			theme: "ppa",
		},
		{
			hosts: ["portal.smartwash.dk"],
			apiUrl: "https://api.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "swa",
		},
		{
			hosts: ["portal365.smartwash.dk"],
			apiUrl: "https://api365.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api365.smartretur.no",
			locales: "main",
			theme: "swa",
		},
	];

	const host = location.hostname.toLowerCase();

	return configs.find((config) => config.hosts.includes(host)) || configs[0];
};

export const getTheme = () => {
	const themes = [
		{
			name: "default",
			title: "SmartRetur Portal",
			logoWhite: "/img/themes/default/logo-white.svg",
			logoBlack: "/img/themes/default/logo-black.svg",
			backgroundImage: "/img/themes/default/bg.jpg",
			themeClassName: "theme-default",
		},
		{
			name: "cma",
			title: "Portal",
			logoWhite: "/img/themes/cma/logo-white.png",
			logoBlack: "/img/themes/cma/logo-black.png",
			backgroundImage: "/img/themes/cma/bg.jpg",
			themeClassName: "theme-cma",
		},
		{
			name: "ppa",
			title: "Portal",
			logoWhite: "/img/themes/ppa/logo-white.svg",
			logoBlack: "/img/themes/ppa/logo-black.png",
			backgroundImage: "/img/themes/ppa/bg.jpg",
			themeClassName: "theme-ppa",
		},
		{
			name: "swa",
			title: "SmartWash Portal",
			logoWhite: "/img/themes/swa/logo-white.svg",
			logoBlack: "/img/themes/swa/logo-black.svg",
			backgroundImage: "/img/themes/swa/bg.jpg",
			themeClassName: "theme-swa",
		},
	];

	const config = getConfig();

	return themes.find((theme) => theme.name === config.theme) || themes[0];
};
